var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-button-group', {
    staticClass: "p-2"
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "outline-dark",
      "disabled": _vm.dataTable.isInserting
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Create ")], 1)], 1)], 1)], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "opacity": 0.5,
      "spinner-variant": "secondary",
      "rounded": "sm"
    }
  }, [_vm.dataTable.dataSet.length === 0 ? _c('div', {
    staticClass: "p-3"
  }, [_vm._v(" No data available ")]) : _vm._e(), _vm.dataTable.dataSet.length > 0 ? _c('v-client-table', {
    ref: "dataTable",
    attrs: {
      "name": "client_table_markets_weights",
      "options": _vm.dataTable.options,
      "columns": _vm.dataTable.columns
    },
    on: {
      "update": _vm.onInlineTableUpdate
    },
    scopedSlots: _vm._u([{
      key: "ID",
      fn: function fn(props) {
        return _c('div', {}, [_vm._v(" " + _vm._s(props.row["ID"]) + " ")]);
      }
    }, {
      key: "Weight (%)",
      fn: function fn(_ref) {
        var row = _ref.row,
          update = _ref.update,
          setEditing = _ref.setEditing,
          isEditing = _ref.isEditing,
          revertValue = _ref.revertValue;
        return _c('div', {
          staticStyle: {
            "width": "120px"
          }
        }, [!isEditing() ? _c('span', {
          on: {
            "click": function click($event) {
              return setEditing(true);
            }
          }
        }, [_vm._v(_vm._s(row["Weight (%)"]))]) : _vm._e(), !isEditing() && !row['Weight (%)'] ? _c('span', {
          staticClass: "pl-5",
          on: {
            "click": function click($event) {
              return setEditing(true);
            }
          }
        }, [_c('b-img')], 1) : _vm._e(), isEditing() ? _c('span', {
          staticClass: "d-flex flex-row align-items-center"
        }, [isEditing() ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: row['Weight (%)'],
            expression: "row['Weight (%)']"
          }],
          staticClass: "form-control flex-grow-1",
          attrs: {
            "type": "text"
          },
          domProps: {
            "value": row['Weight (%)']
          },
          on: {
            "input": [function ($event) {
              if ($event.target.composing) return;
              _vm.$set(row, 'Weight (%)', $event.target.value);
            }, function ($event) {
              _vm.onColumnUpdate(row['ID'], 'Weight (%)', row['Weight (%)']);
            }]
          }
        }) : _vm._e(), !_vm.isNewRecord(row) ? _c('span', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              if (_vm.validateCell('Weight (%)', row['Weight (%)'])) {
                update(row['Weight (%)']);
                setEditing(false);
              }
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "check"
          }
        })], 1) : _vm._e(), !_vm.isNewRecord(row) ? _c('span', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              revertValue();
              setEditing(false);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "ban"
          }
        })], 1) : _vm._e()]) : _vm._e()]);
      }
    }, {
      key: "Valid From",
      fn: function fn(_ref2) {
        var row = _ref2.row,
          update = _ref2.update,
          setEditing = _ref2.setEditing,
          isEditing = _ref2.isEditing,
          revertValue = _ref2.revertValue;
        return _c('div', {
          staticStyle: {
            "width": "80px"
          }
        }, [!isEditing() ? _c('span', {
          on: {
            "click": function click($event) {
              return setEditing(true);
            }
          }
        }, [_vm._v(_vm._s(row["Valid From"]))]) : _c('span', {
          staticClass: "d-flex flex-row align-items-center"
        }, [isEditing() ? _c('date-range-picker-custom', {
          attrs: {
            "single-date-picker": true,
            "ranges": false
          },
          on: {
            "input": function input($event) {
              return _vm.onColumnUpdate(row['ID'], 'Valid From', row['Valid From']);
            }
          },
          model: {
            value: row['Valid From'],
            callback: function callback($$v) {
              _vm.$set(row, 'Valid From', $$v);
            },
            expression: "row['Valid From']"
          }
        }) : _vm._e(), !_vm.isNewRecord(row) ? _c('span', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              update(row['Valid From']);
              setEditing(false);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "check"
          }
        })], 1) : _vm._e(), !_vm.isNewRecord(row) ? _c('span', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              revertValue();
              setEditing(false);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "ban"
          }
        })], 1) : _vm._e()], 1)]);
      }
    }, {
      key: "Actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('clip-loader', {
          staticClass: "mt-1",
          attrs: {
            "size": 14,
            "color": "#36D7B7",
            "loading": _vm.isNewRecord(props.row) && props.row.isInserting === true
          }
        }), _c('div', {
          staticClass: "btn-group"
        }, [_vm.isNewRecord(props.row) && !props.row.isInserting ? _c('div', {
          staticClass: "btn-group"
        }, [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.saveNewRecord(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "check"
          }
        })], 1), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": _vm.revertNewRecord
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "ban"
          }
        })], 1)], 1) : _vm._e(), !_vm.isNewRecord(props.row) ? _c('div', {
          staticClass: "btn-group"
        }, [_c('b-button', {
          attrs: {
            "variant": "danger",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1)], 1) : _vm._e()])], 1);
      }
    }], null, false, 2088150815),
    model: {
      value: _vm.dataTable.dataSet,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTable, "dataSet", $$v);
      },
      expression: "dataTable.dataSet"
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }