<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col lg="2">
            <b-button-group>
              <b-button
                variant="outline-dark"
                :disabled="dataTable.isInserting"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" /> Create
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col>
            <b-overlay
              :show="dataTable.isLoading"
              :opacity="0.5"
              spinner-variant="secondary"
              rounded="sm"
            >
              <div v-if="dataTable.dataSet.length === 0" class="p-3">
                No data available
              </div>
              <div id="card">
                <v-client-table
                  ref="dataTable"
                  name="client_table_dm"
                  v-if="dataTable.dataSet.length > 0"
                  @row-click="onRowClick"
                  @update="onInlineTableUpdate"
                  v-model="dataTable.dataSet"
                  :options="dataTable.options"
                  :columns="dataTable.columns"
                >
                  <div
                    style="width: 15em;"
                    slot="Account Name"
                    slot-scope="{
                      row,
                      update,
                      setEditing,
                      isEditing,
                      revertValue
                    }"
                  >
                    <span v-if="!isEditing()">{{ row["Account Name"] }}</span>
                    <span
                      v-if="!isEditing() && !row['Account Name']"
                      class="pl-5"
                    >
                      <b-img
                    /></span>

                    <span
                      class="d-flex flex-row align-items-center"
                      v-if="isEditing()"
                    >
                      <inline-select
                        :id="row['ID']"
                        v-if="isEditing()"
                        :width="30"
                        :value="row.account"
                        :options="accounts.options"
                        :loading="accounts.loading"
                        :allow-empty="false"
                        :hide-label="true"
                        :multiple="false"
                        :mode="$constants.FORM_MODE.EDIT"
                        :link-mode="false"
                        :async="true"
                        @async-search="onAccountSearch"
                        @changed="onChangeAccountSelect"
                        :required="true"
                      />

                      <span
                        class="btn btn-success btn-sm"
                        @click="
                          if (
                            validateCell('Account Name', row['Account Name'])
                          ) {
                            update(row['Account Name']);
                            setEditing(false);
                          }
                        "
                        v-if="!isNewRecord(row)"
                      >
                        <font-awesome-icon icon="check"
                      /></span>
                      <span
                        class="btn btn-danger btn-sm"
                        @click="
                          revertValue();
                          setEditing(false);
                        "
                        v-if="!isNewRecord(row)"
                      >
                        <font-awesome-icon icon="ban"
                      /></span>
                    </span>
                  </div>

                  <div
                    style="width: 10em;"
                    slot="Market"
                    slot-scope="{
                      row,
                      update,
                      setEditing,
                      isEditing,
                      revertValue
                    }"
                  >
                    <span v-if="!isEditing()">{{ row["Market"] }}</span>
                    <span v-if="!isEditing() && !row['Market']" class="pl-5">
                      <b-img
                    /></span>

                    <span
                      class="d-flex flex-row align-items-center"
                      v-if="isEditing()"
                    >
                      <inline-select
                        :id="row['ID']"
                        v-if="isEditing()"
                        :width="30"
                        :value="row.market"
                        :options="markets.options"
                        :loading="markets.loading"
                        :allow-empty="false"
                        :multiple="false"
                        :hide-label="true"
                        :mode="$constants.FORM_MODE.EDIT"
                        :link-mode="false"
                        :async="false"
                        @changed="onChangeMarketSelect"
                        :required="true"
                      />

                      <span
                        class="btn btn-success btn-sm"
                        @click="
                          if (validateCell('Market', row['Market'])) {
                            update(row['Market']);
                            setEditing(false);
                          }
                        "
                        v-if="!isNewRecord(row)"
                      >
                        <font-awesome-icon icon="check"
                      /></span>
                      <span
                        class="btn btn-danger btn-sm"
                        @click="
                          revertValue();
                          setEditing(false);
                        "
                        v-if="!isNewRecord(row)"
                      >
                        <font-awesome-icon icon="ban"
                      /></span>
                    </span>
                  </div>

                  <div
                    style="width: 10em;"
                    slot="Weight (%)"
                    slot-scope="{
                      row,
                      update,
                      setEditing,
                      isEditing,
                      revertValue
                    }"
                  >
                    <span v-if="!isEditing()">{{ row["Weight (%)"] }}</span>
                    <span
                      v-if="!isEditing() && !row['Weight (%)']"
                      class="pl-5"
                    >
                      <b-img />
                    </span>

                    <span
                      class="d-flex flex-row align-items-center"
                      v-if="isEditing()"
                    >
                      <input
                        v-if="isEditing()"
                        class="form-control  flex-grow-1"
                        type="text"
                        v-model="row['Weight (%)']"
                        @input="
                          onColumnUpdate(
                            row['ID'],
                            'Weight (%)',
                            row['Weight (%)']
                          )
                        "
                      />
                      <span
                        class="btn btn-success btn-sm"
                        @click="
                          if (validateCell('Weight (%)', row['Weight (%)'])) {
                            update(row['Weight (%)']);
                            setEditing(false);
                          }
                        "
                        v-if="!isNewRecord(row)"
                      >
                        <font-awesome-icon icon="check"
                      /></span>
                      <span
                        class="btn btn-danger btn-sm"
                        @click="
                          revertValue();
                          setEditing(false);
                        "
                        v-if="!isNewRecord(row)"
                      >
                        <font-awesome-icon icon="ban"
                      /></span>
                    </span>
                  </div>
                  <div
                    style="width: 10em;"
                    slot="Valid From"
                    slot-scope="{
                      row,
                      update,
                      setEditing,
                      isEditing,
                      revertValue
                    }"
                  >
                    <span v-if="!isEditing()">{{ row["Valid From"] }}</span>
                    <span class="d-flex flex-row align-items-center" v-else>
                      <date-range-picker-custom
                        v-if="isEditing()"
                        v-model="row['Valid From']"
                        :single-date-picker="true"
                        :ranges="false"
                        @input="
                          onColumnUpdate(
                            row['ID'],
                            'Valid From',
                            row['Valid From']
                          )
                        "
                      />
                      <span
                        class="btn btn-success btn-sm"
                        @click="
                          update(row['Valid From']);
                          setEditing(false);
                        "
                        v-if="!isNewRecord(row)"
                      >
                        <font-awesome-icon icon="check"
                      /></span>
                      <span
                        class="btn btn-danger btn-sm"
                        @click="
                          revertValue();
                          setEditing(false);
                        "
                        v-if="!isNewRecord(row)"
                      >
                        <font-awesome-icon icon="ban"
                      /></span>
                    </span>
                  </div>
                  <div slot="Actions" slot-scope="props">
                    <clip-loader
                      class="mt-1"
                      :size="14"
                      color="#36D7B7"
                      :loading="
                        isNewRecord(props.row) && props.row.isInserting === true
                      "
                    />
                    <div class="btn-group">
                      <div
                        class="btn-group"
                        v-if="isNewRecord(props.row) && !props.row.isInserting"
                      >
                        <b-button
                          size="sm"
                          variant="success"
                          @click="saveNewRecord(props.row)"
                        >
                          <font-awesome-icon icon="check" />
                        </b-button>
                        <b-button
                          size="sm"
                          variant="danger"
                          @click="revertNewRecord"
                        >
                          <font-awesome-icon icon="ban" />
                        </b-button>
                      </div>
                      <div class="btn-group" v-if="!isNewRecord(props.row)">
                        <b-button
                          size="sm"
                          @click="toggleWeights(props.row['ID'])"
                        >
                          <font-awesome-icon icon="tasks" />
                        </b-button>

                        <b-button
                          variant="danger"
                          size="sm"
                          @click="deleteItem(props.row.ID)"
                        >
                          <font-awesome-icon icon="trash" />
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <span slot-scope="props" slot="child_row">
                    <markets-weights-table
                      v-if="
                        props.row['ID'] !== $constants.CUSTOM_TABLE.NEW_ROW_ID
                      "
                      :account-id="props.row['Account ID']"
                      :market-id="props.row['Market ID']"
                      @update="onMarketsWeightsUpdate"
                    />
                  </span>
                </v-client-table>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";

import DateRangePickerCustom from "@/components/DateRangePickerCustom";

import MarketsWeightsTable from "@/views/SalesAdministrative/MarketsWeightsTable.vue";
import InlineSelect from "@/components/InlineSelectMultiple";

export default {
  name: "DistributorsMarkets",
  components: {
    InlineSelect,
    MarketsWeightsTable,
    DateRangePickerCustom
  },
  data: function() {
    return {
      accounts: {
        loading: false,
        options: []
      },
      markets: {
        loading: false,
        options: []
      },
      rawData: [],
      dataTable: {
        isLoading: false,
        options: {
          uniqueKey: "ID",
          childRowTogglerFirst: false,
          showChildRowToggler: false,
          filterByColumn: true,
          filterable: [
            "Account Name",
            "Country",
            "State",
            "Market",
            "Weight (%)",
            "Valid From"
          ],
          editableColumns: [
            "Account Name",
            "Market",
            "Weight (%)",
            "Valid From"
          ],
          perPage: 50,
          perPageValues: [],
          saveState: true,
          storage: "local"
        },
        columns: [
          /* 'Account ID', 'Market ID',*/ "Account Name",
          "Country",
          "State",
          "Market",
          "Weight (%)",
          "Valid From",
          "Actions"
        ],
        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0,
        childRow: {
          showTasks: false,
          showFiles: false
        }
      }
    };
  },
  computed: {},
  mounted() {
    let self = this;

    self.$api.get("dictionaries/markets").then(response => {
      self.markets.options = response.map(u => ({
        id: u.id,
        label: u.name
      }));
    });

    this.getData();
  },
  methods: {
    onMarketsWeightsUpdate() {
      this.getData(false);
    },
    async getData(_loading = true) {
      let self = this;

      let response = [];

      try {
        this.dataTable.isLoading = _loading;

        response = await this.$api.get("distributors/markets");

        self.dataTable.isLoading = false;

        if (response.length === 0) return;

        self.rawData = response;

        self.dataTable.dataSet = response;

        //create array of selected values for multiselect input
        this.dataTable.dataSet.forEach(row => {
          row.account = {
            id: row["Account ID"],
            label: row["Account Name"]
          };

          row.market = {
            id: row["Market ID"],
            label: row["Market"]
          };
        });
      } catch (err) {
        self.dataTable.isLoading = false;

        self.$form.msgBoxOk(err.message);
      }
    },
    onRowClick() {},
    onInlineTableUpdate() {},
    onColumnUpdate(id, column, value) {
      let rowIdx = this.dataTable.dataSet.findIndex(item => item.ID === id);

      let row = this.dataTable.dataSet[rowIdx];

      row[column] = value;

      Vue.set(this.dataTable.dataSet, rowIdx, row);
    },
    isNewRecord(row) {
      if (!row) return false;

      return row["ID"] === this.$constants.CUSTOM_TABLE.NEW_ROW_ID;
    },
    saveNewRecord(newRecord) {
      let self = this;

      if (!newRecord.account.id) {
        self.$form.makeToastError("Please select an account");
        return;
      }

      if (!newRecord.market.id) {
        self.$form.makeToastError("Please select a market");
        return;
      }

      if (
        !["int", "float"].includes(
          self.$helpers.parseType2(newRecord["Weight (%)"])
        )
      ) {
        self.$form.makeToastError("Weight must be a number");
        return;
      }

      if (self.$helpers.parseType2(newRecord["Valid From"]) !== "date") {
        self.$form.makeToastError("Invalid date");
        return;
      }

      let data = {
        id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID
      };

      data.account_id = newRecord.account.id;
      data.market_id = newRecord.market.id;
      data.sales_weight = newRecord["Weight (%)"];
      data.valid_from = newRecord["Valid From"];

      newRecord.isInserting = true;

      self.$api
        .post("distributors/markets/weights", data)
        .then(response => {
          newRecord.isInserting = false;

          if (response.message.errorInfo) {
            self.$form.makeToastError(response.message.errorInfo.join());

            return;
          }

          //hide editable cells
          self.$refs.dataTable.$children[0].editing = [];

          newRecord.isInserting = false;

          self.dataTable.isInserting = false;

          self.$form.makeToastInfo(response.message);

          self.getData();
        })
        .catch(function(error) {
          self.$form.makeToastError(error.message);

          newRecord.isInserting = false;

          self.dataTable.isInserting = false;

          self.revertNewRecord();
        });
    },
    async deleteItem(id) {
      let row = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Market "${row["Market"]}" will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      self.$api
        .delete(`distributors/${row.account.id}/markets/${row.market.id}`)
        .then(() => {
          self.$form.makeToastInfo("Successfully deleted!");

          self.getData();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.data.message);

          self.getData();
        });
    },
    toggleWeights(id) {
      this.$refs.dataTable.toggleChildRow(id);
    },
    addItem: function() {
      //moment.utc().format("YYYY-MM-DD")
      let newRecord = {
        ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        "Account Name": "",
        account: {},
        Market: "",
        market: {},
        "Weight (%)": "",
        "Valid From": ""
      };

      this.dataTable.isInserting = true;

      this.dataTable.dataSet.unshift(newRecord);

      Vue.set(this.dataTable.dataSet, 0, newRecord);

      this.$nextTick(() => {
        if (!this.$refs.dataTable) return;

        var _this2 = this.$refs.dataTable.$children[0];

        _this2.editing.push({
          id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          column: "Account Name",
          originalValue: ""
        });

        _this2.editing.push({
          id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          column: "Market",
          originalValue: ""
        });

        _this2.editing.push({
          id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          column: "Weight (%)",
          originalValue: ""
        });

        _this2.editing.push({
          id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          column: "Valid From",
          originalValue: ""
        });
      });
    },
    revertNewRecord() {
      this.dataTable.dataSet.splice(0, 1);

      this.$refs.dataTable.$children[0].editing = [];

      this.dataTable.isInserting = false;
    },
    validateCell(field, value) {
      if (field === "Account Name" && !value.id) {
        self.$form.makeToastError("Please select an account");
        return false;
      }
      if (field === "Market" && !value.id) {
        self.$form.makeToastError("Please select a market");
        return false;
      }

      return true;
    },
    onChangeAccountSelect(id, value) {
      let rowIdx = this.dataTable.dataSet.findIndex(item => item.ID === id);

      let row = this.dataTable.dataSet[rowIdx];

      row.account = value;

      row["Account Name"] = row.account.label;

      Vue.set(this.dataTable.dataSet, rowIdx, row);
    },
    onChangeMarketSelect(id, value) {
      let rowIdx = this.dataTable.dataSet.findIndex(item => item.ID === id);

      let row = this.dataTable.dataSet[rowIdx];

      row.market = value;

      row["Market"] = row.market.label;

      Vue.set(this.dataTable.dataSet, rowIdx, row);
    },

    onAccountSearch(payload) {
      let self = this;

      self.accounts.loading = true;

      this.$api
        .post("dictionaries/accounts", {
          norestrict: true,
          query: payload.query,
          starts_with: payload.startsWith
        })
        .then(response => {
          self.accounts.loading = false;

          self.accounts.options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
    }
  },
  watch: {}
};
</script>

<style scoped>
@media screen and (max-width: 900px) {
  #card ::v-deep table {
    border: 0;
  }

  #card ::v-deep table thead {
    display: none;
  }

  #card .auto-totals {
    display: none;
  }

  #card ::v-deep table tr {
    margin-bottom: 20px;
    display: block;
    border-bottom: 2px solid #ddd;
    box-shadow: 2px 2px 1px #dadada;
  }

  #card ::v-deep table td {
    display: block;
    padding-left: 10em;
    text-align: left;
    font-size: 13px;
  }

  #card ::v-deep table td:last-child {
    border-bottom: 0;
  }

  #card ::v-deep table td::before {
    content: attr(data-label);
    padding-top: 1.3em;
    margin-left: -9em;

    float: left;
    text-transform: uppercase;
    font-weight: bold;
  }

  #card ::v-deep tbody {
    line-height: 0 !important;
  }
}

::v-deep .table td {
  padding: 0.3rem;
}
.editable-cell {
  height: 2.8em;
  border: 1px;
  border-style: dashed;
  border-color: lightgrey;
  display: flex;
  /*  justify-content: center;*/
  align-items: center;
  cursor: pointer;
  padding-left: 1em;
  /*margin-left: 1em;*/
}
</style>
