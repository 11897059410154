<template>
  <div>
    <b-row>
      <b-col lg="4">
        <b-button-group class="p-2">
          <b-button-group>
            <b-button
              size="sm"
              variant="outline-dark"
              :disabled="dataTable.isInserting"
              @click="addItem()"
            >
              <font-awesome-icon icon="plus" /> Create
            </b-button>
          </b-button-group>
        </b-button-group>
      </b-col>
    </b-row>

    <b-overlay
      :show="isLoading"
      :opacity="0.5"
      spinner-variant="secondary"
      rounded="sm"
    >
      <div v-if="dataTable.dataSet.length === 0" class="p-3">
        No data available
      </div>

      <v-client-table
        ref="dataTable"
        name="client_table_markets_weights"
        v-if="dataTable.dataSet.length > 0"
        @update="onInlineTableUpdate"
        v-model="dataTable.dataSet"
        :options="dataTable.options"
        :columns="dataTable.columns"
      >
        <div slot="ID" slot-scope="props">
          {{ props.row["ID"] }}
        </div>

        <div
          style="width: 120px;"
          slot="Weight (%)"
          slot-scope="{ row, update, setEditing, isEditing, revertValue }"
        >
          <span @click="setEditing(true)" v-if="!isEditing()">{{
            row["Weight (%)"]
          }}</span>
          <span
            @click="setEditing(true)"
            v-if="!isEditing() && !row['Weight (%)']"
            class="pl-5"
          >
            <b-img />
          </span>

          <span class="d-flex flex-row align-items-center" v-if="isEditing()">
            <input
              v-if="isEditing()"
              class="form-control  flex-grow-1"
              type="text"
              v-model="row['Weight (%)']"
              @input="
                onColumnUpdate(row['ID'], 'Weight (%)', row['Weight (%)'])
              "
            />
            <span
              class="btn btn-success btn-sm"
              @click="
                if (validateCell('Weight (%)', row['Weight (%)'])) {
                  update(row['Weight (%)']);
                  setEditing(false);
                }
              "
              v-if="!isNewRecord(row)"
            >
              <font-awesome-icon icon="check"
            /></span>
            <span
              class="btn btn-danger btn-sm"
              @click="
                revertValue();
                setEditing(false);
              "
              v-if="!isNewRecord(row)"
            >
              <font-awesome-icon icon="ban"
            /></span>
          </span>
        </div>
        <div
          style="width: 80px;"
          slot="Valid From"
          slot-scope="{ row, update, setEditing, isEditing, revertValue }"
        >
          <span @click="setEditing(true)" v-if="!isEditing()">{{
            row["Valid From"]
          }}</span>
          <span class="d-flex flex-row align-items-center" v-else>
            <date-range-picker-custom
              v-if="isEditing()"
              v-model="row['Valid From']"
              :single-date-picker="true"
              :ranges="false"
              @input="
                onColumnUpdate(row['ID'], 'Valid From', row['Valid From'])
              "
            />
            <span
              class="btn btn-success btn-sm"
              @click="
                update(row['Valid From']);
                setEditing(false);
              "
              v-if="!isNewRecord(row)"
            >
              <font-awesome-icon icon="check"
            /></span>
            <span
              class="btn btn-danger btn-sm"
              @click="
                revertValue();
                setEditing(false);
              "
              v-if="!isNewRecord(row)"
            >
              <font-awesome-icon icon="ban"
            /></span>
          </span>
        </div>
        <div slot="Actions" slot-scope="props">
          <clip-loader
            class="mt-1"
            :size="14"
            color="#36D7B7"
            :loading="isNewRecord(props.row) && props.row.isInserting === true"
          />
          <div class="btn-group">
            <div
              class="btn-group"
              v-if="isNewRecord(props.row) && !props.row.isInserting"
            >
              <b-button
                size="sm"
                variant="success"
                @click="saveNewRecord(props.row)"
              >
                <font-awesome-icon icon="check" />
              </b-button>
              <b-button size="sm" variant="danger" @click="revertNewRecord">
                <font-awesome-icon icon="ban" />
              </b-button>
            </div>
            <div class="btn-group" v-if="!isNewRecord(props.row)">
              <b-button
                variant="danger"
                size="sm"
                @click="deleteItem(props.row.ID)"
              >
                <font-awesome-icon icon="trash" />
              </b-button>
            </div>
          </div>
        </div>
      </v-client-table>
    </b-overlay>
  </div>
</template>

<script>
import DateRangePickerCustom from "@/components/DateRangePickerCustom";

import Vue from "vue";

import { mapState } from "vuex";

export default {
  name: "MarketsWeightsTable",
  props: {
    accountId: {
      type: [String, Number],
      default: ""
    },
    marketId: {
      type: [String, Number],
      default: ""
    }
  },
  components: {
    DateRangePickerCustom
  },
  data: function() {
    return {
      isLoading: false,
      isLoaded: false,
      rawData: [],
      dataTable: {
        isLoading: false,
        options: {
          uniqueKey: "ID",
          childRowTogglerFirst: false,
          showChildRowToggler: false,
          filterByColumn: false,
          filterable: false,
          editableColumns: ["Weight (%)", "Valid From"],
          perPage: 50,
          perPageValues: [],
          saveState: true,
          storage: "local"
        },
        columns: ["ID", "Weight (%)", "Valid From", "Actions"],
        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0,
        childRow: {
          showTasks: false,
          showFiles: false
        }
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  mounted() {
    this.getData();
  },
  methods: {
    isNewRecord(row) {
      if (!row) return false;

      return row["ID"] === this.$constants.CUSTOM_TABLE.NEW_ROW_ID;
    },
    addItem: function() {
      //moment.utc().format("YYYY-MM-DD")
      let newRecord = {
        ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        "Weight (%)": "",
        "Valid From": ""
      };

      this.dataTable.isInserting = true;

      this.dataTable.dataSet.unshift(newRecord);

      Vue.set(this.dataTable.dataSet, 0, newRecord);

      this.$nextTick(() => {
        if (!this.$refs.dataTable) return;

        var _this2 = this.$refs.dataTable.$children[0];

        _this2.editing.push({
          id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          column: "Weight (%)",
          originalValue: ""
        });

        _this2.editing.push({
          id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          column: "Valid From",
          originalValue: ""
        });
      });
    },
    revertNewRecord() {
      this.dataTable.dataSet.splice(0, 1);

      this.$refs.dataTable.$children[0].editing = [];

      this.dataTable.isInserting = false;
    },
    async getData() {
      this.isLoading = true;

      this.$api
        .get(`distributors/${this.accountId}/markets/${this.marketId}/weights`)
        .then(response => {
          this.isLoading = false;

          this.dataTable.rawData = response;

          this.dataTable.dataSet = response;
        });
    },
    onInlineTableUpdate(payload) {
      let self = this;

      let data = {
        id: payload.row["ID"],
        sales_weight: payload.row["Weight (%)"],
        valid_from: payload.row["Valid From"]
      };

      //this.onColumnUpdate(data.id, payload.column, payload["newVal"]);

      this.$api
        .put(`distributors/markets/weights/${data.id}`, data)
        .then(response => {
          self.onColumnUpdate(data.id, payload.column, payload["newVal"]);

          self.$form.makeToastInfo(response.message);

          self.$emit("update");
        })
        .catch(function(error) {
          self.$form.makeToastError(error.statusText);

          self.getData();
        });
    },
    onColumnUpdate(id, column, value) {
      let rowIdx = this.dataTable.dataSet.findIndex(item => item.ID === id);

      let row = this.dataTable.dataSet[rowIdx];

      row[column] = value;

      Vue.set(this.dataTable.dataSet, rowIdx, row);
    },
    validateCell(field, value) {
      let self = this;

      if (
        field === "Weight (%)" &&
        !["int", "float"].includes(self.$helpers.parseType2(value))
      ) {
        self.$form.makeToastError("Weight must be a number");
        return false;
      }

      if (
        field === "Valid From" &&
        self.$helpers.parseType2(value) !== "date"
      ) {
        self.$form.makeToastError("Invalid date");
        return false;
      }

      return true;
    },
    saveNewRecord(newRecord) {
      let self = this;

      if (self.$helpers.parseType2(newRecord["Weight (%)"]) !== "int") {
        self.$form.makeToastError("Weight must be integer");
        return;
      }

      if (self.$helpers.parseType2(newRecord["Valid From"]) !== "date") {
        self.$form.makeToastError("Invalid date");
        return;
      }

      let data = {
        id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID
      };

      data.account_id = this.accountId;
      data.market_id = this.marketId;
      data.valid_from = newRecord["Valid From"];
      data.sales_weight = newRecord["Weight (%)"];

      newRecord.isInserting = true;

      self.$api
        .post("distributors/markets/weights", data)
        .then(response => {
          newRecord.isInserting = false;

          if (response.message.errorInfo) {
            self.$form.makeToastError(response.message.errorInfo.join());

            return;
          }

          //hide editable cells
          self.$refs.dataTable.$children[0].editing = [];

          self.dataTable.isInserting = false;

          self.$form.makeToastInfo(response.message);

          self.getData();

          self.$emit("update");
        })
        .catch(function(error) {
          self.$form.makeToastError(error.message);

          newRecord.isInserting = false;

          self.dataTable.isInserting = false;

          self.revertNewRecord();
        });
    },
    async deleteItem(id) {
      let confirm = await this.$form.showConfirmation(
        "Record will be deleted. Do you want to proceed?"
      );

      if (!confirm) return;

      let self = this;

      self.$api
        .delete(`distributors/markets/weights/${id}`)
        .then(() => {
          self.$form.makeToastInfo("Successfully deleted!");

          self.getData();

          self.$emit("update");
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.data.message);

          self.getData();
        });
    }
  },
  watch: {}
};
</script>

<style scoped>
::v-deep table .multiselect {
  width: 190px;
}

/*
.table-responsive {
  overflow: visible;
}

table {
  overflow: visible !important;
}
*/
</style>
